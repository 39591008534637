// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `unused {
  color: #97b136;
  color: #86a232;
  color: #77952e;

  color: #97b136;
  color: #b13651;
  color: #3651b1;

  color: #97b136;
  color: #b13697;
  color: #3697b1;
}

*::-webkit-scrollbar{
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,cAAc;EACd,cAAc;EACd,cAAc;;EAEd,cAAc;EACd,cAAc;EACd,cAAc;;EAEd,cAAc;EACd,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Poppins&display=swap\");\n\nunused {\n  color: #97b136;\n  color: #86a232;\n  color: #77952e;\n\n  color: #97b136;\n  color: #b13651;\n  color: #3651b1;\n\n  color: #97b136;\n  color: #b13697;\n  color: #3697b1;\n}\n\n*::-webkit-scrollbar{\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
