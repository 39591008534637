// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 774px) {
    .body-margin-top-media{
        margin-top: 80px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/assets/css/container/home/homelanding.css"],"names":[],"mappings":"AAAA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":["@media (max-width: 774px) {\n    .body-margin-top-media{\n        margin-top: 80px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
