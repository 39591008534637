// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main .box{
    align-content: center;
    margin-bottom: 50%;
}
.secBTN {
    background-color: #61615f !important;
    color: #fff !important;
    border: none;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15) !important;


}

.secBTN:hover {
    background-color: #2f2d2f !important;
    transform: scale(1.0);

}

.change-cursor-to-pointer:hover{
    cursor: pointer;
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/assets/css/container/auth/phoneSignup.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,YAAY;IACZ,uDAAuD;;;AAG3D;;AAEA;IACI,oCAAoC;IACpC,qBAAqB;;AAEzB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".main .box{\n    align-content: center;\n    margin-bottom: 50%;\n}\n.secBTN {\n    background-color: #61615f !important;\n    color: #fff !important;\n    border: none;\n    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15) !important;\n\n\n}\n\n.secBTN:hover {\n    background-color: #2f2d2f !important;\n    transform: scale(1.0);\n\n}\n\n.change-cursor-to-pointer:hover{\n    cursor: pointer;\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
