// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thanks{
    margin-top: 50px !important;
}
.adj-height{
    margin-top: 191px !important;
}
.box1{
    align-content: center;
    margin-bottom: 5%;
}

@media (max-width: 768px) {
    .thanks{
        margin-bottom: 60% !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/assets/css/thanks.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,4BAA4B;AAChC;AACA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".thanks{\n    margin-top: 50px !important;\n}\n.adj-height{\n    margin-top: 191px !important;\n}\n.box1{\n    align-content: center;\n    margin-bottom: 5%;\n}\n\n@media (max-width: 768px) {\n    .thanks{\n        margin-bottom: 60% !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
