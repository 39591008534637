// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resultCSS {

    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}
.header{
    color: red;
}
div h2{
    margin-top: 200px;
}

.top{
    margin-top: 200px !important;
}

.total_amount_card {

    background-color: #cbea5b;
    margin-top: 5px !important; 
    justify-content: center !important;
    align-items: center !important;
    /* text-align: center !important; */
  
  
  }
 .calcCSS{
    margin-top: 220px !important;
  }

  .card_text {
    padding-top: 10px !important;
    padding-bottom: 3px !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .container center button{
    justify-content: space-between;
  }

  .AmtCss {
    font-weight: bold;
  }
  @media (max-width: 1168px){
    .calcCSS{
      margin-top: 4rem !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/assets/css/container/scenes/calculator.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,UAAU;AACd;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;IAEI,yBAAyB;IACzB,0BAA0B;IAC1B,kCAAkC;IAClC,8BAA8B;IAC9B,mCAAmC;;;EAGrC;CACD;IACG,4BAA4B;EAC9B;;EAEA;IACE,4BAA4B;IAC5B,8BAA8B;IAC9B,kCAAkC;IAClC,6BAA6B;EAC/B;EACA;IACE,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".resultCSS {\n\n    margin-top: 20px;\n    font-weight: bold;\n    text-align: center;\n}\n.header{\n    color: red;\n}\ndiv h2{\n    margin-top: 200px;\n}\n\n.top{\n    margin-top: 200px !important;\n}\n\n.total_amount_card {\n\n    background-color: #cbea5b;\n    margin-top: 5px !important; \n    justify-content: center !important;\n    align-items: center !important;\n    /* text-align: center !important; */\n  \n  \n  }\n .calcCSS{\n    margin-top: 220px !important;\n  }\n\n  .card_text {\n    padding-top: 10px !important;\n    padding-bottom: 3px !important;\n    justify-content: center !important;\n    text-align: center !important;\n  }\n  .container center button{\n    justify-content: space-between;\n  }\n\n  .AmtCss {\n    font-weight: bold;\n  }\n  @media (max-width: 1168px){\n    .calcCSS{\n      margin-top: 4rem !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
