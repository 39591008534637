// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    border: none !important;

    border-bottom: 1px solid black !important;

}

.form-control {
    border: none !important;
    border-radius: 0%;
    border-bottom: 1px solid black !important;

}

select option[disabled] {
    color: #d2d2d2 !important;
    /* Set the color for the placeholder text */
}

.fontS {
    font-weight: bold;
}

.verifyBtn:hover{
     background-color: #fff !important;
}

@media (max-width: 768px) {

    /* Adjust the font size and padding of the options in the dropdown */

}
.error{
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/assets/css/container/auth/login.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;;IAEvB,yCAAyC;;AAE7C;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,yCAAyC;;AAE7C;;AAEA;IACI,yBAAyB;IACzB,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;KACK,iCAAiC;AACtC;;AAEA;;IAEI,oEAAoE;;AAExE;AACA;IACI,UAAU;AACd","sourcesContent":["input {\n    border: none !important;\n\n    border-bottom: 1px solid black !important;\n\n}\n\n.form-control {\n    border: none !important;\n    border-radius: 0%;\n    border-bottom: 1px solid black !important;\n\n}\n\nselect option[disabled] {\n    color: #d2d2d2 !important;\n    /* Set the color for the placeholder text */\n}\n\n.fontS {\n    font-weight: bold;\n}\n\n.verifyBtn:hover{\n     background-color: #fff !important;\n}\n\n@media (max-width: 768px) {\n\n    /* Adjust the font size and padding of the options in the dropdown */\n\n}\n.error{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
